<template>
  <div class="home-container">
    <div class="box has-background-white-ter no-border-print shared-error" v-if="shareNotFound">
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"
                     v-show="doodleForm.errorMessage"/>
    </div>
    <div class="box main-box no-border-print" v-if="!shareNotFound">
      <div class="has-text-right toggle-section">
      </div>
      <div>
        <div id="code" class="code-editor no-border-print"></div>
      </div>
      <div class="execute-section" v-if="!hideExecute">
        <button class="button execute-button is-info has-text-white has-text-weight-bold" @click="tryExecute">
          <span v-show="!isCodeExecuting"><font-awesome-icon icon="play"/>&nbsp;&nbsp;&nbsp;Execute</span>
          <span v-if="isCodeExecuting">
            <font-awesome-icon icon="spinner" class="fa-spin"/>&nbsp;&nbsp;&nbsp;Executing...</span>
        </button>
      </div>
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"
                     v-show="doodleForm.errorMessage"/>
      <div v-show="showOutput">
        <div class="has-text-weight-semibold ide-title">Output</div>
        <div>
          <div id="output" class="code-editor no-border-print"></div>
        </div>
      </div>
      <div id="ideContainer" class="g-recaptcha is-hidden-print margin-top-20px has-text-centered is-hidden" data-size="invisible" data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi" data-badge="inline"></div>
    </div>
  </div>
</template>

<script>
import aceMixin from './../assets/javascript/ide-mixin'
import { IDE_CONST, ROBOT_CONST } from '../assets/javascript/constants'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import { META } from '../assets/javascript/meta'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'
import { ACE_UTIL } from '@/assets/javascript/ace-util'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'

export default {
  name: 'embedIText',
  components: { DoodleMessage },
  mixins: [aceMixin, formMixin, recaptchaMixin],
  mounted () {
    if (this.$store.state.robotChecked === false) {
      this.loadRecaptcha('ideContainer', this.doRoboCheck)
    }

    this.isEmbed = true
    this.interactiveMode = true

    this.ideMeta = META['java']
    window._.delay(this.initEditors, 500, 1)
    let postAceAction = (count) => {
      if (window.ace) {
        this.pymChild = new window.pym.Child()

        this.pymChild.onMessage('setScript', (data) => {
          data = JSON.parse(data)
          if (data.id === this.$route.query.id) {
            this.initScripts(data)
          }
        })

        this.javaLibraries = this.javaLibs
        this.pymChild.sendMessage('getScript', JSON.stringify({ id: this.$route.query.id }))
      } else if (count < 20) {
        window._.delay(postAceAction, 600, count + 1)
      }
    }
    let postAction = (count) => {
      if (window.pym) {
        window.pym.Child({ polling: 500 })
        window._.delay(postAceAction, 100, 1)
      } else if (count < 20) {
        window._.delay(postAction, 600, count + 1)
      }
    }

    window._.delay(postAction, 600, 1)
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      loadScriptInBody.loadScriptInBody('/assets/javascript/ace.min.js')
        .then(() => {
          loadScriptInBody.loadScriptInBody('/assets/javascript/ext-language_tools.js')
          loadScriptInBody.loadScriptInBody('/assets/javascript/ext-static_highlight.js')
        })
      loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym-original.min.js')
    })
    next()
  },
  computed: {
    language () {
      return this.ideMeta.language
    },
    aceLanguageCode () {
      return this.ideMeta.aceCode ? this.ideMeta.aceCode : this.ideMeta.language
    }
  },
  methods: {
    getUrl (file) {
      return `/api/projectSync/downloadItem?projectKey=1001&name=${encodeURI(file)}&type=output`
    },
    ideExecute () {
      this.execute()
    },
    postExecuteSuccessHandling () {

    },
    initScripts (data) {
      this.project = data

      if (this.project.libs) {
        this.project.libs = this.project.libs.split(',')
      }

      this.onLanguageChange()
    },
    onLanguageChange () {
      this.outputFiles = []
      this.showOutput = false
      this.ideMeta = META[this.project.language]
      if (this.project.versionIndex) {
        this.versionIndex = parseInt(this.project.versionIndex)
      }
      window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(this.project.script)
      ACE_UTIL.changeLanguage(this.codeEditor, this.aceLanguageCode)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";
  @import "./../assets/style/ide";

  .home-container {
    margin: 1em;
    background: #F2F2F2;
  }

  .main-box {
    padding: 0.5em 1.2em;
    background: #F2F2F2;
  }

  .ide-title, .switch[type=checkbox] + label.ide-title {
    font-size: 0.9em;
    margin-top: 1em;
  }

  .column {
    padding-top: 0.15em;
    padding-bottom: 0.10em;
  }

  .execute-section {
    text-align: right;
    margin-right: 3%;
    position: relative;
  }

  .interactive-mode-top {
    margin-top: 0.75em;
  }

  .embed-brand-area {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .info-text {
    font-size: 0.85em;
    color: black;
    font-weight: 500;
  }

  .powered-by {
    vertical-align: bottom;
  }

  #code {
    min-height: 10px;
  }

  #code .ace-tomorrow-night .ace_gutter, .toggle-java {
    color: #F99D25;
  }

  .switch[type=checkbox]:checked+label::before, .switch[type=checkbox]:checked+label:before {
    background: #F99D25;
  }

  .switch[type=checkbox]+label::before, .switch[type=checkbox]+label:before {
    background: #F99D25;
  }

  .toggle-section {
    background: #1D1F21;
    padding: 5px;
  }

  .clipboard {
    width: 15px;
    height: 15px;
  }

  .clipboard-link {
    padding: 5px;
    background: #F99D25;
    margin-left: 10px;
  }

  .upload-pointer {
    cursor: pointer;
  }

  .upload-message {
    color: #F99D25;
  }

  .count-tag {
    color: #F99D25;
    font-weight: bold;
    border-radius: 20px;
  }

  #files-menu {
    position: absolute;
    right: 120px;
    bottom: 35px;
    vertical-align: top;
    z-index: 9999;
  }

  .folder-note {
    word-break: break-all;
  }

</style>
